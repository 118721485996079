<template>
  <v-container>
      <v-img
      src="@/assets/antares_presentation/titre.png"
      class="mx-auto mb-8"
      max-width="500"
      ></v-img>
      <v-img
      src="@/assets/logo-antares.png"
      class="mx-auto"
      max-width="250"
      ></v-img>
      <div>
        <v-row>
          <v-col>
            <h2 class="text-left titre">Présentation</h2>
          </v-col>
        </v-row>
      </div>

    <v-divider class="mb-4"></v-divider>

    <v-row class="ligne-0">
      <v-col>
        <span class="text-justify mt-5"
          >Nous proposons des activités variées. De la plus ludique à la plus
          ambitieuse, nous tâchons de satisfaire les envies du plus grand nombre
          tout en réunissant les passionnés d’aérospatial dans un cadre
          convivial et stimulant.
        </span>
      </v-col>
    </v-row>

    <v-row class="ligne-1">
      <v-col cols="5">
        <v-img
        src="@/assets/antares_presentation/fusee1.png"
        max-width="500"
        ></v-img>
      </v-col>
      <v-col cols="7">
        <h1 class="text-left">
          Ceci est un titre
        </h1>
        <span class="text-justify text-activite mt-5"
          >Nous proposons des activités variées. De la plus ludique à la plus
          ambitieuse, nous tâchons de satisfaire les envies du plus grand nombre
          tout en réunissant les passionnés d’aérospatial dans un cadre
          convivial et stimulant.
        </span>
      </v-col>
    </v-row>

    <v-divider class="ligne-1"></v-divider>

    <v-row class="ligne-2">
      <v-col cols="7">
        <h1 class="text-left">
          Ceci est un autre titre
        </h1>
        <span class="text-justify text-activite mt-5"
          >Nous proposons des activités variées. De la plus ludique à la plus
          ambitieuse, nous tâchons de satisfaire les envies du plus grand nombre
          tout en réunissant les passionnés d’aérospatial dans un cadre
          convivial et stimulant.
        </span>
      </v-col>
      <v-col cols="5">
        <v-img
        src="@/assets/antares_presentation/fusee2.png"
        max-width="500"
        ></v-img>
      </v-col>
    </v-row>
    
    <div>
      <v-row>
        <v-col>
          <h2 class="text-left titre">L'équipe sur le projet</h2>
        </v-col>
      </v-row>
    </div>

    <v-divider class="mb-4"></v-divider>

    <v-img
      src="@/assets/antares_presentation/equipe.png"
      class="mx-auto mb-8"
      max-width="800"
      ></v-img>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>
.ligne-0, .ligne-1{
  margin-bottom: 25px;
}

.titre {
  font-size: 35px;
  font-weight: bolder;
  color: #193154;
}
</style>